<template>
    <div ref="container" class="content-container">
      <b-alert
          class="alert"
          :show="alertDismissCountDown"
          dismissible
          fade
          :variant="variant"
          @dismissed="alertDismissCountDown=0"
          @dismiss-count-down="alertCountDownChanged"
      >
        {{ alertMessage }}
      </b-alert>
      <map-container
                ref="mapContainer"
                :find-user="true"
                :center="mapCenter"
                @editAreaEvent="editAreaEvent"
                @onMapChanged="onMapChanged"
                @onMapClicked="mapClicked"
                @onPolylineTap="onPolylineTap"
                @onZoomLevelChange="onZoomLevelChange"
                @onBoundingBoxChange="onBoundingBoxChange"
                @onMapInfoClosed="onMapInfoClosed"
                @onPolylineHover="onPolylineHover"
                @onPolylineHoverExit="onPolylineHoverExit"
                @onGeoJsonHoverEvent="onGeoJsonHoverEvent"
                @onGeoJsonHoverExitEvent="onGeoJsonHoverExitEvent"
                @onGeoJsonClicked="onGeoJsonClicked"
                @pointerCoordinateMove="pointerCoordinateMove"
                @onMarkerTap="onMarkerTap"
                @onMultiMarkerTap="onMultiMarkerTap"
                @onMarkerPointerEnter="onMarkerPointerEnter"
                @onDrag="onDrag"
                @onDragEnd="onDragEnd"
                :menuItems="menuItems"
                :menu-enabled="menuEnabled"/>
        <!-- Map buttons on top , no buttons for map viewer-->
        <div
            v-if="hasAccess && !isMapViewer"
            class="map-button-container">
            <div class="col-lg-10 m-0 p-0">
                <div class="map-button-content">
                    <div
                        v-if="isAdmin || isObserver || isWorker"
                        :class="[vehiclesEnabled ? btnSelected : btnUnselected]"
                        @click.stop="toggleVehicles"
                    >
                        <font-awesome-icon icon="car"/>
                    </div>
                    <div class="map-button-title" v-bind:class="{'text-selected' : vehiclesEnabled}">{{ $t('map_tooltips.vehicle_tracking') }}</div>
                </div>
                <div class="map-button-content">
                    <div
                        :class="[roadLinkEnabled ? btnSelected : btnUnselected]"
                        @click.stop="toggleRoadLink">
                        <font-awesome-icon icon="road"/>
                    </div>
                    <div class="map-button-title" v-bind:class="{'text-selected' : roadLinkEnabled}">{{ $t('map_tooltips.road_information') }}</div>
                </div>
                <div class="map-button-content">
                    <div
                        :class="[areasEnabled ? btnSelected : btnUnselected]"
                        @click.stop="toggleAreas">
                        <font-awesome-icon icon="draw-polygon"/>
                    </div>
                    <div class="map-button-title" v-bind:class="{'text-selected' : areasEnabled}">{{ $t('map_tooltips.areas') }}</div>
                </div>
                <div class="map-button-content">
                    <div
                        :class="[observationsEnabled ? btnSelected : btnUnselected]"
                        @click.stop="toggleObservations">
                        <font-awesome-icon icon="map-marker"/>
                    </div>
                    <div class="map-button-title" v-bind:class="{'text-selected' : observationsEnabled}">{{ $t('map_tooltips.open_observations') }}</div>
                </div>
                <div
                    v-if="isAdmin || isObserver"
                    class="map-button-content">
                    <div
                        :class="[usersEnabled ? btnSelected : btnUnselected]"
                        @click.stop="toggleUsers">
                        <font-awesome-icon icon="user"/>
                    </div>
                    <div class="map-button-title" v-bind:class="{'text-selected' : usersEnabled}">{{ $t('map_tooltips.persons') }}</div>
                </div>
                <div class="map-button-content hidden">
                    <div
                        :class="[skiingEnabled ? btnSelected : btnUnselected]"
                        @click.stop="toggleSkiTracks">
                        <font-awesome-icon icon="skiing-nordic"/>
                    </div>
                    <div class="map-button-title" v-bind:class="{'text-selected' : skiingEnabled}">{{ $t('map_tooltips.skiing_tracks') }}</div>
                </div>
                <div class="map-button-content">
                    <div
                        v-if="isWorker || isObserver || isAdmin"
                        :class="[storagesEnabled ? btnSelected : btnUnselected]"
                        @click.stop="toggleStorages">
                        <font-awesome-icon icon="warehouse"/>
                    </div>
                    <div class="map-button-title" v-bind:class="{'text-selected' : storagesEnabled}">{{ $t('map_tooltips.material_storages') }}</div>
                </div>
                <div class="map-button-content">
                    <div
                        v-if="isWorker || isObserver || isAdmin"
                        :class="[stationsEnabled ? btnSelected : btnUnselected]"
                        @click.stop="toggleStations">
                        <font-awesome-icon icon="industry"/>
                    </div>
                    <div class="map-button-title" v-bind:class="{'text-selected' : stationsEnabled}">{{ $t('map_tooltips.material_stations') }}</div>
                </div>
                <div class="map-button-content">
                    <div
                        v-if="isWorker || isObserver || isAdmin"
                        :class="[weatherStationsEnabled ? btnSelected : btnUnselected]"
                        @click.stop="toggleWeatherStations">
                        <font-awesome-icon icon="cloud-sun"/>
                    </div>
                    <div class="map-button-title" v-bind:class="{'text-selected' : weatherStationsEnabled}">{{ $t('map_tooltips.weather_stations') }}</div>
                </div>
                <div class="map-button-content">
                    <div
                        v-if="isWorker || isObserver || isAdmin"
                        :class="[workAssignmentsEnabled ? btnSelected : btnUnselected]"
                        @click.stop="toggleWorkAssignments">
                        <font-awesome-icon icon="tasks"/>
                    </div>
                    <div class="map-button-title" v-bind:class="{'text-selected' : workAssignmentsEnabled}">{{ $t('map_tooltips.work_assignments') }}</div>
                </div>
                <div v-if="isWorker || isObserver" class="map-button-content">
                    <div
                        :class="[infraEnabled ? btnSelected : btnUnselected]"
                        @click.stop="toggleInfraData">
                        <font-awesome-icon icon="wrench"/>
                    </div>
                    <div class="map-button-title" v-bind:class="{'text-selected' : infraEnabled}">{{ $t('map_tooltips.infra_data') }}</div>
                </div>
                <div
                    v-if="selectedContracts.length === 1 && selectedContracts[0].external_contract &&
                    selectedContracts[0].external_contract.id && (isWorker || isObserver)"
                    class="map-button-content">
                    <div
                        :class="[velhoEnabled ? btnSelected : btnUnselected]"
                        @click.stop="toggleVelhoData">
                        <font-awesome-icon icon="hat-wizard"/>
                    </div>
                    <div class="map-button-title" v-bind:class="{'text-selected' : velhoEnabled}">Velho</div>
                </div>

            </div>

        </div>
        <road-links
            v-if="roadLinkEnabled"
            ref="roadLink"
            :map="map"
        />


        <div class="map-controls" :class="{'pt-1' : !hasAccess}">
            <div class="map-controls__list col-12 col-lg-4 nopads">
                <map-contract-filter />
                <!-- Map layers -->
                <map-areas
                    v-if="areasEnabled"
                    ref="mapAreas"
                    :map="map"
                    :company="company"
                    :selectedContractId="selectedContractId"
                    :selectedContractIds="selectedContractIds"
                    :showLabels="areaLabelsVisible"
                    @updateAreaLabelsVisibility="toggleAreaLabels"
                />

                <map-work-road-snapshot
                    v-if="showRoadWorkHistory && map"
                    ref="snapshot"
                    :fromDate="historyFromDate"
                    :toDate="historyToDate"
                    :roadOwner="historyRoadOwner"
                    :selectedContractId="selectedContractId"
                    :selectedContractIds="selectedContractIds"
                    :selectedTaskType="selectedTaskType"
                    :selectedTaskTypes="selectedTaskTypes"
                    :selectedOrder="selectedOrder"
                    :map="map"
                />

                <map-vehicles
                    v-if="vehiclesEnabled"
                    ref="vehicles"
                    :map="map"
                    :is-admin="isAdmin"
                    :is-map-viewer="isMapViewer"
                    :is-observer="isObserver"
                    :showHistory="showRoadWorkHistory"
                    :road-owner="historyRoadOwner"
                    @historyChange="onWorkHistoryChanged"
                />

                <observation-map
                    v-if="observationsEnabled"
                    :map="map"
                    :selectedContractId="selectedContractId"
                    :selectedContractIds="selectedContractIds"
                    :selected-order="selectedOrder"
                    :require-bounding-box="true"
                    :bounding-box="boundingBox"
                    :showTimeFilters="selectedContractIds !== null || selectedContractId !== null"
                    :is-observer="isObserver"
                    ref="observations"
                    @scrollTop="scrollTop"
                />
                <traffic-control-map
                    v-if="trafficControlItemsEnabled"
                    :map="map"
                    :selectedContractId="selectedContractId"
                    :selectedContractIds="selectedContractIds"
                    :selected-order="selectedOrder"
                    :require-bounding-box="true"
                    :bounding-box="boundingBox"
                    :showTimeFilters="selectedContractIds !== null || selectedContractId !== null"
                    :is-observer="isObserver"
                    ref="trafficControlMap"
                    @scrollTop="scrollTop"
                    @removeMeasureRoad="removeMeasureRoads"
                />
                <map-area-work-history
                    style="float: right; z-index: 2; margin-top: 1px"
                    v-if="newPolygonId > 0"
                    ref="workHistory"
                    :area="newPolygonId"
                    @onAddWork="showAreaWorkEditor"/>
                <map-users
                    v-if="usersEnabled"
                    ref="users"
                    :selectedContractId="selectedContractId"
                    :selectedContractIds="selectedContractIds"
                    :map="map"
                />
                <material-storage-map
                    v-if="storagesEnabled"
                    ref="storages"
                    :map="map"
                    :bounding-box="boundingBox"
                    :visible-storages="visibleStorages"
                    :selectedContractId="selectedContractId"
                    :selectedContractIds="selectedContractIds"
                    :require-bounding-box="true"
                    @settingsChanged="storageSettingsChanged"
                />
                <selection-tool
                    v-if="polygonDrawing"
                    :polygonDrawing="polygonDrawing"
                    @showSelectionSelector="showSelectionSelector"
                    @clearPolygon="clearPolygon"
                    @close="hidePolygonDrawing"
                />
                <infra-map
                    v-if="infraEnabled"
                    ref="infra"
                    :map="map"
                    :user="user"
                    :is-admin="isAdmin"
                    :bounding-box="boundingBox"
                    :selected-contract-ids="selectedContractIds"
                />
                <velho-map
                    v-if="velhoEnabled && selectedContracts.length === 1"
                    ref="velho"
                    :map="map"
                    :selected-contract="selectedContracts[0]"
                    :bounding-box="boundingBox"
                />
                <material-station-map
                    v-if="stationsEnabled"
                    ref="stations"
                    :map="map"
                />
                <weather-station-map
                    v-if="weatherStationsEnabled"
                    ref="weatherStations"
                    :map="map"
                    :bounding-box="boundingBox"
                    :require-bounding-box="true"
                />
                <map-skiing
                    v-if="skiingEnabled"
                    ref="skiTracks"
                    :map="map"
                    :user="user"
                />

                <user-alert-map ref="userAlerts" :map="map"/>

                <work-assignment-map
                    v-if="workAssignmentsEnabled"
                    ref="workAssignments"
                    :map="map"
                    :selectedContractId="selectedContractId"
                    :selectedContractIds="selectedContractIds"
                    :selectedOrder="selectedOrder"
                    :require-bounding-box="true"
                    :bounding-box="boundingBox"
                />

                <measure-distance-map
                    v-if="measureDistanceEnabled"
                    ref="measureDistances"
                    :map="map"
                    style="float: right; z-index: 2"
                    :measure-distance-results="measureDistances"
                    :measure-distance-target-item="measureDistanceTargetItem"
                    :measure-distance-target-item-callback="measureDistanceTargetItemCallback"
                    :draggable="true"
                    @onRemoveMeasureDistances="cancelLineDrawing"
                />

                <measure-road-map
                    v-if="measureRoadEnabled"
                    ref="measureRoad"
                    :map="map"
                    style="float: right; z-index: 2"
                    :draggable="true"
                    :show-make-new-traffic-control-button="true"
                    @close="measureRoadEnabled = false"
                    @confirmSelection="createLineObservation"
                    @createNewTrafficControlItem="createNewTrafficControlItem"
                />

                <map-imported-objects
                    v-if="showImportedObjects"
                    style="float: right; z-index: 2"
                    @hideImportedObjects="hideImportedObjects"
                />
            </div>
        </div>

        <!-- company area dialog, Admin only -->
        <b-modal
            ref="companyAreaSelectionModal"
            centered
            hide-footer
            :title="$t('areas.select_company')"
        >
            <b-row>
                <company-filter v-model="company"/>
            </b-row>
            <div class="button-container top-margin">
                <b-button
                    variant="success"
                    size="lg"
                    @click.stop="showAreas"
                    :disabled="!company"
                >
                    <span class="map-button-text">{{ $t('areas.fetch_areas') }}</span>
                </b-button>
            </div>
        </b-modal>
        <material-storage-editor-modal
            v-model="editStorage"
            @cancel="editStorage = null"
            @closeAndUpdate="closeAndUpdateStorage"
            :user="user"
        />

        <weather-position-details-modal
            v-model="weatherStation"
            @cancel="weatherStation = null"
            :weather-station-id="weatherStation === null ? null : weatherStation.id"
            :weather-station-name="weatherStation === null ? null : weatherStation.name"
        />

        <order-geometry-property-modal
            v-if="showPreviewPropertiesModal"
            :properties="propertiesPreview"
            :excludedFields="excludedFields"
            @setproperties="setProperties"
            @close="closePropertiesPreviewModal"
        />
        <material-station-modal
            v-model="editStation"
            @cancel="editStation = null"
            @closeAndUpdate="closeAndUpdateStation"
            :is-observer="isObserver"
            :is-worker="isWorker"
        />

        <!-- TODO - Replace this modal with full editor -->
        <b-modal
            ref="assignmentEditor"
            body-class="p-0 m-0"
            class="nopads"
            size="lg"
            centered
            hide-header
            hide-footer
            @cancel="hideAssignmentEditor"
            >
                    <work-assignment-editor
                        style="width: 100%"
                        :is-admin="false"
                        :coord="showWorkAssignmentEditorOnCoord"
                        :is-observer="isObserver"
                        :user="user"
                        :no-margin="true"
                        :work-assignment="editWorkAssignment"
                        @close="hideAssignmentEditor"
                        @closeAndUpdate="closeAndUpdateWorkAssignment"
                    />
        </b-modal>
        <observation-bulk-editor
            v-if="editMode && observationDialog"
            id="bulkEditor"
            :observations="selectedItems"
            :user="user"
            :is-observer="isObserver"
            @scrollTop="scrollTop"
            @close="closeForm"
            @closeAndUpdate="closeAndUpdateObservations"
        />
        <work-assignment-editor-dialog
            v-if="editMode && assignmentDialog"
            :is-admin="isAdmin"
            :is-observer="isObserver"
            :assignments="selectedItems"
            @showWorkAssignments="showWorkAssignments"
            @closeForm="closeForm"
            @closeAndUpdate="closeAndUpdate"
        />
        <road-sign-bulk-editor-dialog
            v-if="editMode && roadSignDialog"
            :is-admin="isAdmin"
            :is-observer="isObserver"
            :road-signs="selectedItems"
            @showRoadSigns="showRoadSigns"
            @closeForm="closeForm"
            @closeAndUpdate="closeAndUpdateRoadSigns"
        />
        <street-light-bulk-editor
            v-if="editMode && streetLightDialog"
            :street-lights="selectedItems"
            @close="closeForm"
            @closeAndUpdate="closeAndUpdateStreetLights"
        />
        <sewer-item-bulk-editor-dialog
            v-if="editMode && (sewerLinesDialog || sewerDrainsDialog)"
            :sewer-items="selectedItems"
            :updateSewerLines="sewerLinesDialog"
            :updateSewerDrains="sewerDrainsDialog"
            @close="closeForm"
            @closeAndUpdate="closeAndUpdateSewerItems"
        />
        <map-selection-editor
            v-if="selectionSelector"
            :options="selectionOptions"
            @close="hideSelectionSelector"
            @onSelectionSelected="onSelectionSelected"/>

        <address-search-wizard
            :map="map"
            v-if="showAddressSearchWizardOnCoord !== null"
            :coordinates="showAddressSearchWizardOnCoord"
            @close="showAddressSearchWizardOnCoord = null"
        />
        <road-search-wizard
            :map="map"
            v-if="showRoadSearchWizardOnCoord !== null"
            :coordinates="showRoadSearchWizardOnCoord"
            @close="showRoadSearchWizardOnCoord = null"
            @roadSelected="addGeoJsonMapObjects"
        />
        <work-search-wizard
            :map="map"
            v-if="showWorkSearchWizardOnCoord !== null"
            :coordinates="showWorkSearchWizardOnCoord"
            :contracts="selectedContracts"
            @close="showWorkSearchWizardOnCoord = null"
        />
        <observation-wizard
            v-if="showObservationWizardOnCoord !== null"
            :coordinates="showObservationWizardOnCoord"
            :observation-item="measureDistanceTargetItem"
            :contract="selectedContractId"
            :contractIds="selectedContractIds"
            @close="showObservationWizardOnCoord = null"
            @closeAndUpdate="closeAndUpdateObservation"
            @lineRequest="lineForObservation"
        />
        <road-sign-wizard
            v-if="showRoadSignWizardOnCoord !== null || roadSign != null"
            :selectedContract="selectedContracts.length === 1 ? selectedContracts[0] : null"
            :coordinate="showRoadSignWizardOnCoord"
            :road-sign="roadSign"
            :is-admin="isAdmin"
            :user="user"
            @close="closeRoadSignWizard"
            @closeAndUpdate="closeAndUpdateRoadSign"
        />
        <velho-equipment-wizard
            v-if="showVelhoEquipmentWizardOnCoord !== null"
            :coordinate="showVelhoEquipmentWizardOnCoord"
            :selectedContract="selectedContracts.length === 1 ? selectedContracts[0] : null"
            @close="closeVelhoEquipmentWizard"
        />
        <map-layer-selector
          v-if="mapLayerSelector"
          @close="hideMapLayerSelector"
          @onMapSelected="onMapSelected"/>
        <import-file-wizard
            ref="importfilewizardref"
            v-if="showImportShapeFileWizardOnCoord !== null"
            :coordinate="showImportShapeFileWizardOnCoord"
            :road-sign="roadSign"
            :is-admin="isAdmin"
            :user="user"
            @close="closeImportShapeFileWizard"
            @addLayer="addGeoJsonMapObjects"
        />
        <area-work-editor
            v-if="editAreaId"
            :user="user"
            :area-id="editAreaId"
            @close="editAreaId = undefined"
            @closeAndUpdate="refreshWorkHistory"/>
        <b-modal
            ref="selectMarkerModal"
            centered
            hide-footer
            :title="$t('map.select_marker_modal')"
        >
            <map-item-selector
                v-if="map"
                :marker-store="map.getMarkerStore()"
                :markers="markerSelections"
                @mapItemSelected="onMarkerTap"/>
        </b-modal>
        <danger-report-modal
            :show-report="showDangerReportCreateForm"
            :coordinates="coordinates"
            :selectedContract="selectedContracts.length === 1 ? selectedContracts[0] : null"
            @close="hideDangerReport"
        />
        <div
            v-if="loading"
            id="loader"
            class="spinner"
        />
    </div>
</template>

<script>
import RoadLinks from '../roadlinkinfo/RoadLinks'
import MapAreas from '../area/MapAreas'
import MapVehicles, {historyModes} from '../vehicle/MapVehicles'
import {userHelper} from '../mixins/UserMixin'
import ObservationMap from '../observation/ObservationMap'
import {restApi} from '../mixins/RestApiMixin'
import {mapHelper} from '../mixins/MapMixin'
import {geometryMixin} from '../mixins/GeometryMixin'
import {roadSignHelper} from '@/components/mixins/RoadSignMixin'
import MapUsers from '../user/MapUsers'
import CompanyFilter from '../company/CompanyFilter'
import MaterialStorageMap from '../materialstorage/MaterialStorageMap'
import MaterialStorageEditorModal from '../materialstorage/MaterialStorageEditorModal'
import TrafficControlMap from "@/components/trafficcontrol/TrafficControlMap";
import MaterialStationModal from "@/components/materials/station/MaterialStationModal";
import MaterialStationMap from "@/components/materials/station/MaterialStationMap";
import ObservationWizard from '../observation/ObservationWizard'
import {newMaterialStorage} from '../materialstorage/MaterialStorageHelper'
import MapSkiing from "../sports/MapSkiing";
import SelectionTool from "./SelectionTool";
import UserAlertMap from "@/components/user/alert/UserAlertMap"
import RoadSignWizard from "@/components/roadsign/RoadSignWizard";
import VelhoEquipmentWizard from "@/components/velho/VelhoEquipmentWizard";
import RoadSignBulkEditorDialog from "@/components/roadsign/RoadSignBulkEditorDialog";
import {workManagementHelper} from "@/components/mixins/WorkManagementMixin";
import MapContainer from "./MapContainer";
import MapLayerSelector from "./MapLayerSelector";
import WorkAssignmentMap from "@/components/workassignment/WorkAssignmentMap";
import MeasureDistanceMap from "@/components/map/MeasureDistanceMap";
import ImportFileWizard from "../shapeimport/ImportFileWizard";
import MapImportedObjects from "../shapeimport/MapImportedObjects";
import MeasureRoadMap from "./MeasureRoadMap";
import {measureDistanceMixin} from "../mixins/MeasureDistanceMixin";
import {selectFromMapMixin} from "../mixins/SelectFromMapMixin";
import MapSelectionEditor from "./MapSelectionEditor";
import WorkAssignmentEditorDialog from "../workassignment/WorkAssignmentEditorDialog";
import WorkAssignmentEditor from "@/components/workassignment/WorkAssignmentEditor";
import AddressSearchWizard from '../search/AddressSearchWizard.vue'
import MapContractFilter from '../contract/MapContractFilter.vue'
import {mapActions, mapState} from "vuex";
import {EventBus} from "@/event-bus";
import RoadSearchWizard from "@/components/search/RoadSearchWizard";
import WorkSearchWizard from "@/components/search/WorkSearchWizard";
import ObservationBulkEditor from "@/components/observation/ObservationBulkEditor";
import StreetLightBulkEditor from "@/components/streetlights/StreetLightBulkEditor";
import OrderGeometryPropertyModal from "@/components/order/OrderGeometryPropertyModal";
import InfraMap from "@/components/infra/InfraMap.vue";
import MapAreaWorkHistory from "@/components/area/areawork/MapAreaWorkHistory.vue";
import AreaWorkEditor from "@/components/area/areawork/AreaWorkEditor.vue";
import SewerItemBulkEditorDialog from "@/components/stormsewers/SewerItemBulkEditorDialog";
import materialStationMap from "@/components/materials/station/MaterialStationMap.vue";
import WeatherStationMap from "@/components/weather/WeatherStationMap.vue";
import weatherPositionDetailsModal from "@/components/weather/WeatherPositionDetailsModal.vue";
import VelhoMap from "@/components/velho/VelhoMap";
import MapItemSelector from "@/components/map/MapItemSelector";
import DangerReportModal from "@/components/dangerreports/DangerReportModal.vue";
import MapWorkRoadSnapshot from "@/components/vehicle/MapWorkRoadSnapshot";

export default {
    name: 'MapView',
    components: {
        MapWorkRoadSnapshot,
        DangerReportModal,
        MapItemSelector,
        VelhoMap,
        weatherPositionDetailsModal,
        WeatherStationMap,
        materialStationMap,
        AreaWorkEditor,
        MapAreaWorkHistory,
        InfraMap,
        SewerItemBulkEditorDialog,
        OrderGeometryPropertyModal,
        StreetLightBulkEditor,
        ObservationBulkEditor,
        RoadSearchWizard,
        WorkSearchWizard,
        WorkAssignmentEditor,
        WorkAssignmentEditorDialog,
        MapSelectionEditor,
        MeasureRoadMap,
        MapImportedObjects,
        TrafficControlMap,
        MeasureDistanceMap,
        WorkAssignmentMap,
        MapLayerSelector,
        SelectionTool,
        MapContainer,
        RoadSignWizard,
        RoadSignBulkEditorDialog,
        VelhoEquipmentWizard,
        UserAlertMap,
        ObservationWizard,
        MaterialStorageEditorModal,
        MaterialStorageMap,
        MaterialStationModal,
        MaterialStationMap,
        MapSkiing,
        CompanyFilter,
        MapUsers,
        ObservationMap,
        MapAreas,
        RoadLinks,
        MapVehicles,
        ImportFileWizard,
        AddressSearchWizard,
        MapContractFilter
    },
    mixins: [userHelper, restApi, mapHelper, workManagementHelper, selectFromMapMixin,measureDistanceMixin, geometryMixin, roadSignHelper],
    props: {
        user: {
            type: Object,
            default: null
        },
        isAdmin: Boolean,
        isObserver: Boolean,
        isWorker: Boolean,
        isMapViewer: Boolean,
        menuEnabled: {
            type: Boolean,
            default: function () {
                return true
            }
        },
        logoutEvent: Boolean,
        center: {
            type: Object,
            default: null
        },
        zoom: {
            type: Number,
            default: 12
        },
        findUser: {
            type: Boolean,
            default: function () {
                return true
            }
        },
        stationsVisible: {
            type: Boolean,
            default: function () {
                return false
            }
        }
    },
    data: function () {
        return {
            observationDialog: false,
            assignmentDialog: false,
            roadSignDialog: false,
            streetLightDialog: false,
            sewerLinesDialog: false,
            sewerDrainsDialog: false,
            selectionSelector: false,
            historyFromDate: null,
            historyToDate: null,
            loading: false,
            markerSelections: [],
            boundingBox: [],
            mapZoomLevel: null,
            propertiesPreview: null,
            showPreviewPropertiesModal: false,
            selectedAreas: [],
            selectedAreaTypes: [],
            areaLabelsVisible: false,
            areaCollection: null,
            userAlerts: [],
            measureDistances: [],
            selectedItems: [],
            selectedItem: null,
            loadedGeometries: [],
            selectionReady: false,
            newPolygonId: -1,
            newPolygonPointCount: 0,
            newAreaName: null,
            selectedAreaType: null,
            selectedCompany: null,
            polygonDrawing: false,
            roadLinkEnabled: undefined,
            vehiclesEnabled: undefined,
            areasEnabled: undefined,
            dailyRoutesEnabled: false,
            trafficControlItemsEnabled: undefined,
            observationsEnabled: undefined,
            storagesEnabled: undefined,
            editStorage: null,
            stationsEnabled: undefined,
            weatherStationsEnabled: undefined,
            editStation: null,
            editMode: false,
            editWorkAssignment: null,
            editMeasureDistance: null,
            roadSign: null,
            showAddressSearchWizardOnCoord: null,
            showRoadSearchWizardOnCoord: null,
            showWorkSearchWizardOnCoord: null,
            showObservationWizardOnCoord: null,
            showWorkAssignmentEditorOnCoord: null,
            showRoadSignWizardOnCoord: null,
            showVelhoEquipmentWizardOnCoord: null,
            showImportShapeFileWizardOnCoord: null,
            setShowObservationTitle: false,
            setShowStorageTitle: false,
            showImportedObjects: false,
            usersEnabled: undefined,
            skiingEnabled: undefined,
            observationDetailsMode: false,
            map: null,
            mapCenter: null,
            hasAccess: false,
            btnUnselected: 'map-button',
            btnSelected: 'map-button-selected',
            company: null,
            workAssignmentsEnabled: undefined,
            infraEnabled: false,
            velhoEnabled: false,
            selectionEnabled: false,
            measureDistanceEnabled: false,
            measureDistanceTargetItem: null,
            measureRoadEnabled: false,
            alertDismissSecs: 5,
            alertDismissCountDown: 0,
            alertMessage: '',
            variant: 'danger',
            mapLayerSelector: false,
            showRoadWorkHistory: false,
            historyRoadOwner: null,
            visibleStorages: 1,
            editAreaId: null,
            weatherStation: null,
            showDangerReportCreateForm: false,
            coordinates: null,
        }
    },
    watch: {
        user: function () {
            this.hasAccess = this.hasAdmin(this.user.roles) || this.hasObserver(this.user.roles) || this.hasWorker(this.user.roles) || this.hasMapViewer(this.user.roles)
        },
        selectedContracts: function () {
          if (this.selectedContracts.length !== 1) {
               this.velhoEnabled = false
           } else if (!this.selectedContracts[0].external_contract || !this.selectedContracts[0].external_contract.id) {
               this.velhoEnabled = false
           }
            this.updateMapSettings({ buttons: { velhoEquipment: this.velhoEnabled }});
            this.saveMapSettings();
        },
        selectedContractId: function() {
            this.handleGeoJsonObjects()
            if(this.observationsEnabled) {
                this.showObservations()
            }
        },
        selectedContractIds: function() {
            this.handleGeoJsonObjects()
            if(this.observationsEnabled) {
                this.showObservations()
            }
        },
        selectedOrder() {
            if(this.observationsEnabled) {
                this.showObservations()
            }
        },
        showContractGeometry() {
            this.handleGeoJsonObjects()
        },
        selectedGeometryOrders() {
            this.handleGeoJsonObjects()
        },
        showContractGeometryLabels() {
            this.updateLabels()
        },
        showTrafficControl() {
            this.handleTrafficControl()
        }
    },
    async mounted() {
        if (this.stationsVisible){
            this.stationsEnabled = true
        }
        this.handleTrafficControl()
        this.handleGeoJsonObjects()
        this.hasAccess = this.hasAdmin(this.user.roles) || this.hasObserver(this.user.roles) || this.hasWorker(this.user.roles) || this.hasMapViewer(this.user.roles)
        if(this.getMapSettingsIn(['center'])){
            this.mapCenter = this.getMapSettingsIn(['center'])
        } else if (this.user && this.user.company.location) {
            this.mapCenter = {
                lon: this.user.company.location.x,
                lat: this.user.company.location.y,
            }
        } else if (this.center) {
            this.mapCenter = this.center
        }
        if (this.zoom) {
            this.mapZoom = this.zoom
        }
        if (this.vehiclesEnabled === undefined) {
            this.vehiclesEnabled = this.getMapSettingsIn(['buttons', 'vehicles'], { });
            this.historyRoadOwner = this.getMapSettingsIn(['buttons', 'vehicles', 'history', 'roadOwner'], null);
        }
        if (this.roadLinkEnabled === undefined) {
            this.roadLinkEnabled = this.getMapSettingsIn(['buttons', 'roadLink'], false);
        }
        if (this.areasEnabled === undefined) {
            this.areasEnabled = this.getMapSettingsIn(['buttons', 'areas']) != null;
            this.company = this.getMapSettingsIn(['buttons', 'areas', 'company'], null);
        }
        if (this.observationsEnabled === undefined) {
            this.observationsEnabled = !!this.getMapSettingsIn(['buttons','observations']);
        }
        if (this.observationsEnabled) {
            this.showObservations()
        }
        if (this.usersEnabled === undefined) {
            this.usersEnabled = this.getMapSettingsIn(['buttons','users'], false);
        }
        if (this.skiingEnabled === undefined) {
            this.skiingEnabled = !!this.getMapSettingsIn(['buttons','skiing']);
        }
        if (this.storagesEnabled === undefined) {
            this.storagesEnabled = this.getMapSettingsIn(['buttons','storages'], null);
            this.visibleStorages = this.getMapSettingsIn(['buttons', 'storages', 'visibleStorages'], 1);
        }
        if (this.stationsEnabled === undefined) {
            this.stationsEnabled = this.getMapSettingsIn(['buttons','stations'], false);
        }
        if (this.weatherStationsEnabled === undefined) {
            this.weatherStationsEnabled = this.getMapSettingsIn(['buttons','weatherStations'], false);
        }
        if (this.roadSignsEnabled === undefined) {
            this.roadSignsEnabled = this.getMapSettingsIn(['buttons','roadSigns'], false);
        }
        if (this.roadSignsEnabled) {
            this.showRoadSigns()
        }
        if (this.workAssignmentsEnabled === undefined) {
            this.workAssignmentsEnabled = this.getMapSettingsIn(['buttons','workAssignments'], false);
        }
        if (this.workAssignmentsEnabled) {
            this.showWorkAssignments();
        }
        if (this.streetLightsEnabled === undefined) {
            this.streetLightsEnabled = this.getMapSettingsIn(['buttons','streetLights'], false);
        }
        this.velhoEnabled = this.getMapSettingsIn(['buttons','velhoEquipment'], false);
    },
    computed: {
        ...mapState({
            selectedContractId: (state) => state.contracts.selectedContractId,
            selectedContract: (state) => state.contracts.selectedContract,
            selectedContractIds: (state) => state.contracts.selectedContractIds,
            selectedGeometryOrders: (state) => state.contracts.selectedGeometryOrders,
            selectedContracts: (state) => state.contracts.selectedContracts,
            showContractGeometry: (state) => state.contracts.showContractGeometry,
            showContractGeometryLabels: (state) => state.contracts.showContractGeometryLabels,
            showTrafficControl: (state) => state.contracts.showTrafficControl,
            selectedTaskType: (state) => state.contracts.selectedTaskType,
            selectedTaskTypes: (state) => state.contracts.selectedTaskTypes,
            selectedOrder: (state) => state.contracts.selectedOrder
        }),
        isElyContractSelected() {
            return this.selectedContracts.length === 1 && this.selectedContracts[0].external_contract && !!this.selectedContracts[0].external_contract.id && !!(this.isWorker || this.isObserver)
        },
        menuItems() {
            return [
                {
                    text: this.$t('map.search'),
                    subItems: [
                        {
                            text: this.$t('map.search_address'),
                            onClick: this.openAddressSearchWizard
                        },
                        {
                            text: this.$t('map.search_road'),
                            onClick: this.openRoadSearchWizard
                        },
                        {
                            text: this.$t('map.search_road_address_location'),
                            onClick: this.searchRoadAddress
                        },
                        {
                            text: this.$t('map.search_work'),
                            onClick: this.openWorkSearchWizard
                        },
                    ]
                },
                {
                    text: this.$t('map.add_new'),
                    subItems: [
                        {
                            text: this.$t('map.create_observation'),
                            onClick: this.openObservationWizard
                        },
                        {
                            text: this.$t('map.create_work_assignment'),
                            onClick: this.openWorkAssignmentEditor,
                            disabled: !this.isObserver
                        },
                        {
                            text: this.$t('map.add_road_sign'),
                            onClick: this.openRoadSignWizard
                        },
                        ...(this.isElyContractSelected ? [{
                            text: this.$t('map.add_velho_equipment'),
                            onClick: this.openVelhoEquipmentWizard,

                        }] : []),
                        {
                            text: this.$t('map.add_traffic_control'),
                            onClick: this.initMeasureRoad
                        },
                        {
                            text: this.$t('map.add_street_light'),
                            onClick: this.openStreetLightWizard,
                            disabled: !this.isObserver
                        },
                        {
                            text: this.$t('map.add_power_center'),
                            onClick: this.openPowerStationWizard,
                            disabled: !this.isObserver
                        },
                        {
                            text: this.$t('materialstorage.title'),
                            onClick: this.addMaterialStorage,
                            disabled: !this.isObserver
                        },
                        {
                            text: this.$t('material_station.title'),
                            onClick: this.addMaterialStation,
                            disabled: !this.isObserver
                        },
                        {
                            text: this.$t('map.add_sewer_drain'),
                            onClick: this.openDrainWizard,
                            disabled: !this.isObserver
                        },
                        {
                            text: this.$t('map.danger_report'),
                            onClick: this.openDangerReport
                        },
                    ]
                },
                !this.measureDistanceEnabled ? {
                    text: this.$t('map.measure_distance'),
                    onClick: this.initMeasureDistance
                } : {
                    text: this.$t('map.remove_measurements'),
                    onClick: this.removeMeasureDistances
                },
                !this.measureRoadEnabled ? {
                    text: this.$t('map.measure_road'),
                    onClick: this.initMeasureRoad
                } : {
                    text: this.$t('map.remove_measurements'),
                    onClick: this.removeMeasureRoads
                },
                {
                    text: this.$t('map.layers'),
                    onClick: this.showMapLayerSelector
                },
                {
                    text: this.$t('map.import_geo_file'),
                    onClick: this.openImportShapeFileWizard
                },
                !this.polygonDrawing ? {
                    text: this.$t('map.select_assets'),
                    onClick: this.enablePolygonDrawing
                } : {
                    text: this.$t('map.end_selection'),
                    onClick: this.showSelectionSelector
                }
            ]
        },
        selectionOptions() {
            return [
                [this.OBSERVATION, this.observationsEnabled],
                [this.WORK_ASSIGNMENT, this.workAssignmentsEnabled],
                [this.TRAFFIC_SIGN, this.infraEnabled],
                [this.STREET_LIGHT, this.infraEnabled],
                [this.SEWERS, this.infraEnabled],
                [this.DRAINS, this.infraEnabled],
                [this.SEWERS_AND_DRAINS, this.infraEnabled],
            ].filter(e => e[1]).map(e => e[0])
        }
    },
    methods: {
        ...mapActions('contracts', ['selectContract', 'selectTaskType', 'setShowTrafficControl']),
        getWorkAssignmentPointPosition(workAssignment){
            let markerPosition = null
            if (workAssignment.geometry) {
                if (workAssignment.geometry.polygon) {
                    markerPosition = {
                        'x': workAssignment.geometry.polygon.rings[0][0][0],
                        'y': workAssignment.geometry.polygon.rings[0][0][1]
                    }
                } else if (workAssignment.geometry.point) {
                    markerPosition = workAssignment.geometry.point
                } else if (workAssignment.geometry.line_string) {
                    markerPosition = {
                        'x': workAssignment.geometry.line_string.points[0][0],
                        'y': workAssignment.geometry.line_string.points[0][1]
                    }
                }
            } else if (workAssignment.position) {
                markerPosition = workAssignment.position
            } else if (workAssignment.observation && workAssignment.observation.geometry.point) {
                markerPosition = workAssignment.observation.geometry.point
            } else if (workAssignment.observation && workAssignment.observation.geometry.line_string){
                let geometry = workAssignment.observation.geometry.line_string
                markerPosition = {'x': geometry.points[0][0], 'y': geometry.points[0][1]}
            } else if (workAssignment.area_defect && workAssignment.area_defect.area_equipment && workAssignment.area_defect.area_equipment.location){
                markerPosition = workAssignment.area_defect.area_equipment.location
            } else if(workAssignment.area_equipment && workAssignment.area_equipment.location){
                markerPosition = workAssignment.area_equipment.location
            }
            return markerPosition
        },
        setProperties(value) {
            this.propertiesPreview = value
        },
        hideSelectionSelector(){
            this.selectionSelector = false
        },
        hidePolygonDrawing() {
            this.polygonDrawing = false
            this.clearPolygon()
        },
        showSelectionSelector() {
            if (this.newPolygonPointCount > 2) {
                this.selectAssets(this.$refs.observations ? this.$refs.observations.getVisibleObservations() : [],
                    this.$refs.workAssignments ? this.$refs.workAssignments.getVisibleWorkAssignments() : [],
                    this.$refs.infra ? this.$refs.infra.getVisibleSigns() : [],
                    this.$refs.infra ? this.$refs.infra.getStreetLights() : [],
                    this.$refs.infra ? this.$refs.infra.getSewerLines() : [],
                    this.$refs.infra ? this.$refs.infra.getSewerDrains() : []
                )
                if(this.selectedItems.length > 0) {
                    this.selectionSelector = true
                    this.selectedItems = []
                } else {
                    this.clearPolygon()
                }
            }
        },
        onSelectionSelected(selection){
            this.selectedItems = []
            if(selection === this.OBSERVATION){
                this.selectObservationAssets(this.$refs.observations.getVisibleObservations())
                this.observationDialog = this.selectedItems && this.selectedItems.length > 0
            }else if(selection === this.WORK_ASSIGNMENT){
                this.selectWorkAssignmentAssets(this.$refs.workAssignments.getVisibleWorkAssignments())
                this.assignmentDialog = this.selectedItems && this.selectedItems.length > 0
            }else if(selection === this.TRAFFIC_SIGN) {
                this.selectRoadSignAssets(this.$refs.infra.getVisibleSigns())
                this.roadSignDialog = this.selectedItems && this.selectedItems.length > 0
            } else if(selection === this.STREET_LIGHT) {
                this.selectStreetLightAssets(this.$refs.infra.getStreetLights())
                this.streetLightDialog = this.selectedItems && this.selectedItems.length > 0
            } else if(selection === this.SEWERS) {
                this.selectSewerLineAssets(this.$refs.infra.$refs.sewers.getSewerLines())
                this.sewerLinesDialog = this.selectedItems && this.selectedItems.length > 0
            } else if(selection === this.DRAINS) {
                this.selectSewerDrainAssets(this.$refs.infra.$refs.sewers.getSewerDrains())
                this.sewerDrainsDialog = this.selectedItems && this.selectedItems.length > 0
            } else if(selection === this.SEWERS_AND_DRAINS) {
                let selectedSewerLineAssets = this.selectSewerLineAssets(this.$refs.infra.$refs.sewers.getSewerLines())
                let selectedSewerDrainAssets = this.selectSewerDrainAssets(this.$refs.infra.$refs.sewers.getSewerDrains())
                this.sewerLinesDialog = selectedSewerLineAssets && selectedSewerLineAssets.length > 0
                this.sewerDrainsDialog = selectedSewerDrainAssets && selectedSewerDrainAssets.length > 0
            } else {
                this.hideSelectionSelector()
                return
            }
            this.hideSelectionSelector()
            this.editMode = this.selectedItems && this.selectedItems.length > 0
        },
        mapClicked: function (coord) {
            // Pass to visible function
            if (this.roadLinkEnabled) {
                this.$refs.roadLink.mapClicked(coord)
            }
            if (this.measureDistanceEnabled) {
                this.addMeasureDistance(coord)
            }
            if (this.measureRoadEnabled) {
                this.$refs.measureRoad.addMeasurePoint(coord)
            }
            if(this.polygonDrawing && !this.isEditing) {
                this.addPointToPolygon(coord)
            }
        },

        selectAssets(observations, workAssignments, roadSigns, streetLights, sewerLines, sewerDrains){
            this.selectObservationAssets(observations)
            this.selectWorkAssignmentAssets(workAssignments)
            this.selectRoadSignAssets(roadSigns)
            this.selectStreetLightAssets(streetLights)
            this.selectSewerLineAssets(sewerLines)
            this.selectSewerDrainAssets(sewerDrains)
        },

        selectStreetLightAssets(streetLights) {
            let polygon = this.getPolygon()
            if(streetLights.length > 0) {
                streetLights.forEach(light => {
                    if (this.isPointInPolygon(light.position.y, light.position.x, polygon)) {
                        this.selectedItems.push(light)
                  }
                })
            }
        },

        selectObservationAssets(observations) {
            let polygon = this.getPolygon()
            if(observations.length > 0) {
                observations.map((observation) => {
                    if(observation.geometry && observation.geometry.point) {
                        if(this.isPointInPolygon(observation.geometry.point.y, observation.geometry.point.x, polygon)) {
                            this.selectedItems.push(observation)
                        }
                    } else if(observation.geometry && observation.geometry.line_string &&
                        observation.geometry.line_string.points) {
                        if(this.isPointInPolygon(observation.geometry.line_string.points[0][1], observation.geometry.line_string.points[0][0], polygon)) {
                            this.selectedItems.push(observation)
                        }
                    }
                })
            }
        },
        selectWorkAssignmentAssets(workAssignments){
            let polygon = this.getPolygon()
            if(workAssignments.length > 0) {
                workAssignments.map((workAssignment) => {
                    let pointPosition = this.getWorkAssignmentPointPosition(workAssignment)
                    if(pointPosition != null && this.isPointInPolygon(pointPosition.y, pointPosition.x, polygon)) {
                        this.selectedItems.push(workAssignment)
                    }
                })
            }
        },

        selectRoadSignAssets(roadSigns){
            let polygon = this.getPolygon()
            if(roadSigns.length > 0) {
                roadSigns.map((roadSign) => {
                    let pointPosition = this.getWorkAssignmentPointPosition(roadSign)
                    if(pointPosition != null && this.isPointInPolygon(pointPosition.y, pointPosition.x, polygon)) {
                        this.selectedItems.push(roadSign)
                    }
                })
            }
        },

        selectSewerLineAssets(sewerLines) {
            let polygon = this.getPolygon()
            let results = []
            if(sewerLines && sewerLines.length > 0) {
                sewerLines.map((sewerLine) => {
                    if(sewerLine.geometry && sewerLine.geometry.point) {
                        if(this.isPointInPolygon(sewerLine.geometry.point.y, sewerLine.geometry.point.x, polygon)) {
                            sewerLine.type = this.SEWERS
                            this.selectedItems.push(sewerLine)
                            results.push(sewerLine)
                        }
                    } else if(sewerLine.geometry && sewerLine.geometry.line_string &&
                        sewerLine.geometry.line_string.points) {
                        if(this.isPointInPolygon(sewerLine.geometry.line_string.points[0][1], sewerLine.geometry.line_string.points[0][0], polygon)) {
                            sewerLine.type = this.SEWERS
                            this.selectedItems.push(sewerLine)
                            results.push(sewerLine)
                        }
                    }
                })
            }
            return results
        },

        selectSewerDrainAssets(sewerDrains) {
            let polygon = this.getPolygon()
            let results = []
            if(sewerDrains && sewerDrains.length > 0) {
                sewerDrains.map((sewerDrain) => {
                    if(sewerDrain.geometry && sewerDrain.geometry.point) {
                        if(this.isPointInPolygon(sewerDrain.geometry.point.y, sewerDrain.geometry.point.x, polygon)) {
                            sewerDrain.type = this.DRAINS
                            this.selectedItems.push(sewerDrain)
                            results.push(sewerDrain)
                        }
                    } else if(sewerDrain.geometry && sewerDrain.geometry.line_string &&
                        sewerDrain.geometry.line_string.points) {
                        if(this.isPointInPolygon(sewerDrain.geometry.line_string.points[0][1], sewerDrain.geometry.line_string.points[0][0], polygon)) {
                            sewerDrain.type = this.DRAINS
                            this.selectedItems.push(sewerDrain)
                            results.push(sewerDrain)
                        }
                    } else if(sewerDrain.position && sewerDrain.position.x && sewerDrain.position.y) {
                        if(this.isPointInPolygon(sewerDrain.position.y, sewerDrain.position.x, polygon)) {
                            sewerDrain.type = this.DRAINS
                            this.selectedItems.push(sewerDrain)
                            results.push(sewerDrain)
                        }
                    }
                })
            }
            return results
        },

        getPolygon: function () {
            let rings = this.$refs.mapContainer.getMap().getPolygonBoundaries(this.newPolygonId, this.OTHER)
            // We need only outer rings as this is used for map item selection
            if (rings.length > 0) {
                return rings[0].map(item => [item['lng'], item['lat']])
            }
            return []
        },

        closeForm: function () {
            this.editMode = false
            this.assignmentDialog = false
            this.observationDialog = false
            this.roadSignDialog = false
            this.sewerLinesDialog = false
            this.sewerDrainsDialog = false
        },

        closeAndUpdateObservations() {
            this.closeAndUpdate()
            this.$refs.observations.fetchObservations(true)
        },

        closeAndUpdateStreetLights() {
            this.closeAndUpdate()
            this.$refs.streetLightMap.fetchStreetLights()
        },

        closeAndUpdateRoadSigns() {
            this.closeAndUpdate()
            this.$refs.infra.$refs.roadSigns.updateView()
        },

        closeAndUpdateSewerItems() {
            this.closeAndUpdate()
            this.$refs.infra.$refs.sewers.fetchSewers()
            this.$refs.infra.$refs.sewers.fetchDrains()
        },

        closeAndUpdate: function () {
            this.editMode = false
        },

        refreshWorkHistory() {
            this.editAreaId = undefined
            this.$refs.workHistory.refreshHistory()
        },

        showAreaWorkEditor: function (areaId) {
            this.editAreaId = areaId
        },

        editAreaEvent: function (id) {
            //This allows us to store the id of the polygon which is in focus
            //i.e. it was clicked on by the user
            if (!this.polygonDrawing) {
                this.editingAreaEventFlag = true
                this.newPolygonId = id
            }
        },

        addPointToPolygon: function (point) {
            // If not exist yet, create one and add to map
            if (this.newPolygonId < 0) {
                this.newPolygonId = Date.now()
                this.newPolygonPointCount = 1
                this.$refs.mapContainer.getMap().newPolygon(this.newPolygonId, point.lat, point.lng, this.OTHER)

            } else {
                this.newPolygonPointCount++
                this.$refs.mapContainer.getMap().addPointToPolygon(this.newPolygonId, point.lat, point.lng, this.OTHER)
            }
        },

        clearPolygon: function () {
            if (this.newPolygonId > 0) {
                this.$refs.mapContainer.getMap().removePolygon(this.newPolygonId, this.OTHER)
            }
            this.newPolygonId = -1
            this.newPolygonPointCount = 0
        },

        onPolylineTap: function (data) {
            this.$refs.mapContainer.hideMapInfo()
            // Pass to corresponding function
            if (data.type && data.type === this.ROAD && this.roadLinkEnabled) {
                this.$refs.roadLink.onPolylineTap(data)
            }
            if (data.type && data.type === this.OBSERVATION) {
                this.showObservationDetails(data)
            } else if (this.vehiclesEnabled && this.hasAccess) {
                this.$refs.vehicles.onPolylineTap(data)
            }
            if (data.type && data.type === this.SKI_TRACK && this.skiingEnabled) {
                this.$refs.skiTracks.onPolylineTap(data)
            }
            if (data.type && data.type === this.SEWERS && this.infraEnabled) {
                this.$refs.infra.$refs.sewers.editSewerLine(data.id)
            }
            if (data.type && data.type === this.TRAFFIC_CONTROL_ITEM) {
                this.showTrafficControlItemDetails(data)
            }
            if (data.type && data.type === this.EXTERNAL_RAILINGS) {
                // this.showTrafficControlItemDetails(data)
                this.$refs.velho.editRailing(data.id)
            }
            if (data.type && data.type === this.EXTERNAL_FENCES) {
                // this.showTrafficControlItemDetails(data)
                this.$refs.velho.editFence(data.id)
            }
            if (data.type && data.type === this.EXTERNAL_EDGE_SUPPORTS) {
                // this.showTrafficControlItemDetails(data)
                this.$refs.velho.editEdgeSupport(data.id)
            }
            if(data.type && data.type === this.EXTERNAL_EDGE_PILES) {
                this.$refs.velho.editEdgePile(data.id)
            }
            if(data.type && data.type === this.EXTERNAL_LIGHTING) {
                this.$refs.velho.editLighting(data.id)
            }
            if(data.type && data.type === this.EXTERNAL_PIPES_WIRES_AND_CABLES) {
                this.$refs.velho.editPipeWireCable(data.id)
            }
        },

        onBoundingBoxChange: function (data) {
            // This triggers other components to update their contents
            this.boundingBox = data
        },

        onZoomLevelChange(data) {
            this.mapZoomLevel = Math.round(data)
            this.onBoundingBoxChange(this.map.getViewBoundsCoordinates())
        },

        onGeoJsonClicked(feature, layer) {
            this.showPreviewPropertiesModal = true
            this.propertiesPreview = this.map.getMapType() === 'HERE' ? layer.getData() : feature.properties
        },

        closePropertiesPreviewModal() {
            this.propertiesPreview = null
            this.showPreviewPropertiesModal = false
        },

        onPolylineHover: function (data) {
            switch (data.type) {
                case this.WORK_TRACE:
                    this.$refs.snapshot.showLabel(data)
                    break
                case this.OBSERVATION:
                    this.showObservationTitle(data)
                    break
            }
        },

        onPolylineHoverExit: function (data) {
            switch (data.type) {
                case this.WORK_TRACE:
                    this.$refs.snapshot.hideLabel()
                    break
            }
        },

        onMapInfoClosed: function () {
            // Pass to corresponding function
            if (this.roadLinkEnabled) {
                this.$refs.roadLink.onMapInfoClosed()
            }
            if (this.vehiclesEnabled) {
                this.$refs.vehicles.onMapInfoClosed()
            }
        },

        onMarkerPointerEnter: function (data) {
            switch (data.type) {
                case this.OBSERVATION:
                    this.showObservationTitle(data)
                    break
                case this.MATERIAL_STORAGE:
                    this.showStorageTitle(data)
                    break
            }
        },

        onMarkerPointerLeave: function (data) {
            switch (data.type) {
                case this.OBSERVATION:
                    this.hideObservationTitle(data)
                    break
                case this.MATERIAL_STORAGE:
                    this.hideStorageTitle(data)
                    break
            }
        },

        onDrag: function (data) {
            if (this.measureDistanceEnabled) {
                this.updateMeasureDistance(data)
            }
        },

        onDragEnd: function (data) {
            if (this.measureDistanceEnabled) {
                this.updateMeasureDistance(data)
            }
            if (this.measureRoadEnabled) {
                this.$refs.measureRoad.addMeasurePoint(data)
            }
        },

        showAlert: function (message, variant) {
            this.alertDismissCountDown = this.alertDismissSecs
            this.alertMessage = message
            if (variant) {
                this.variant = variant
            } else {
                this.variant = 'danger'
            }
        },

        alertCountDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown
        },

        storageSettingsChanged(storageSelection) {
            this.visibleStorages = storageSelection
            this.updateMapSettings({ buttons: { storages: { visibleStorages: storageSelection }}});
            this.saveMapSettings();
        },

        showVehicleInfo: function (data) {
            if (this.vehiclesEnabled) {
                if (this.hasAccess) {
                    this.$refs.vehicles.onMarkerTap(data)
                }
            }
        },

        showObservationTitle: function (data) {
            if (this.observationsEnabled && !this.setShowObservationTitle) {
              this.setShowObservationTitle = true
              try {
                  this.$refs.observations.showObservationTitle(data)
                  setTimeout(() => {
                      this.hideObservationTitle(data)
                  }, 1200)
              } catch (e) {
                  this.setShowObservationTitle = false
              }
            }
        },

        hideObservationTitle: function (data) {
            // Hide info bubble
            if (this.observationsEnabled) {
                this.setShowObservationTitle = false
                this.$refs.observations.hideObservationTitle(data)
            }
        },

        showStorageTitle: function (data) {
            if (this.storagesEnabled && !this.setShowStorageTitle) {
                this.setShowStorageTitle = true
                try {
                    this.$refs.storages.showStorageTitle(data)
                    setTimeout(() => {
                        this.hideStorageTitle(data)
                    }, 1000)
                } catch (e) {
                    this.setShowStorageTitle = false
                }
            }
        },

        hideStorageTitle: function (data) {
            // Hide info bubble
            if (this.storagesEnabled) {
                this.setShowStorageTitle = false
                if (this.$refs.storages) {
                    this.$refs.storages.hideStorageTitle(data)
                }
            }
        },

        showObservationDetails: function (data) {
            if (this.observationsEnabled) {
                this.$refs.observations.onMarkerTap(data)
            }
        },

        showTrafficControlItemDetails: function (data) {
            if (this.trafficControlItemsEnabled) {
                this.$refs.trafficControlMap.onPolylineTap(data)
            }
        },


        openDangerReport: function (coord) {
            this.coordinates = {"x": coord.lng, "y": coord.lat}
            this.showDangerReportCreateForm = true
        },

        hideDangerReport: function () {
            this.showDangerReportCreateForm = false
        },

      showMapLayerSelector: function () {
        this.mapCenter = this.map.getMapCenter()
        this.mapZoom = this.map.getMapZoomLevel()
        this.mapLayerSelector = true
      },

      onMapChanged(map) {
        this.map = map;
        if (this.mapCenter) {
            this.map.zoomToPosition(this.mapCenter.lat, this.mapCenter.lng, this.mapZoom)
        }
        const bbox = this.map.getViewBoundsCoordinates()
        if(this.isValidBoundingBox(bbox)) {
            this.onBoundingBoxChange(bbox)
        }
      },

      isValidBoundingBox(bbox) {
            return bbox.length === 4 && Math.abs(bbox[0]-bbox[3]) < 10 && Math.abs(bbox[1]-bbox[3] < 10)
      },

      async onMapSelected(layer) {
        this.loading = true
        this.hideImportedObjects()
        await this.$refs.mapContainer.setMap(layer);
        this.addLoadedGeometries()
        await this.saveMapSettings();
        this.hideMapLayerSelector()
        this.loading = false
      },

        addLoadedGeometries: function() {
            if(this.loadedGeometries) {
                this.addLoadedGeoJsonMapObjects()
            }
        },

      hideMapLayerSelector: function () {
        this.mapLayerSelector = false
      },

        toggleRoadLink: function () {
            if (this.roadLinkEnabled) {
                this.disableRoadLinkSearch()
            } else {
                this.enableRoadLinkSearch()
            }
        },

        enableRoadLinkSearch: function () {
            this.updateMapSettings({ buttons: { roadLink: true } });
            this.saveMapSettings();
            this.roadLinkEnabled = true
        },

        disableRoadLinkSearch: function () {
            this.updateMapSettings({ buttons: { roadLink: false } });
            this.saveMapSettings();
            this.roadLinkEnabled = false
        },

        enablePolygonDrawing: function () {
            this.selectionReady = false;
            this.newPolygonId = -1
            this.newPolygonPointCount = 0
            this.newAreaName = null
            this.selectedAreaType = null
            this.selectedCompany = null
            this.polygonDrawing = true
            this.$refs.mapContainer.getMap().removeAreaEventListeners()
            this.$bvToast.toast(`${this.$t('map.select_assets_info')}`, {
                title: this.$t('map.select_assets'),
                toaster: 'b-toaster-bottom-center',
                id: 'map-selection-toast',
                autoHideDelay: 10000,
                solid: true
            });
        },

        disablePolygonDrawing: function () {
            this.polygonDrawing = false
            this.$bvToast.hide('map-selection-toast')
        },

        hideLoadedGeoJsonObjects: function () {
            if(this.map) {
                this.map.hideGeoJsonObjects()
                this.removeLabels()
                this.loadedGeometries = []
            }
        },

        createNewTrafficControlItem(coordinates, properties) {
            let trafficControlItem = null
            if(coordinates) {
                trafficControlItem = {
                    id: null,
                    geometry: this.setAsLineString(coordinates),
                    start_time: null,
                    end_time: null,
                    contract: this.selectedContracts && this.selectedContracts.length === 1 ? this.selectedContracts[0] : null,
                    road_name: properties && properties.roadName ? properties.roadName : "",
                    road_number: properties && properties.roadNumber ? properties.roadNumber : null,
                    road_section: properties && properties.roadSection ? properties.roadSection : null,
                    description: ""
                }
            }
            if(!this.trafficControlItemsEnabled) {
                this.trafficControlItemsEnabled = true
            }
            this.$nextTick(() => {
                this.$refs.trafficControlMap.setNewTrafficControlItem(trafficControlItem)
            })
        },

        updateLabels() {
            this.loadedGeometries.forEach((item,index) => {
                this.updateLabel(index, this.showContractGeometryLabels)
            })
        },

        toggleAreaLabels(toggle) {
            this.areaLabelsVisible = toggle
        },

        addLoadedGeoJsonMapObjects() {
            this.loadedGeometries.forEach((item,index) => {
                this.updateLabel(index, this.showContractGeometryLabels)
                if(item.visibility) {
                    for (const index in item.geometry.features) {
                        item.geometry.features[index].properties.order_id = item.order_id
                    }
                    this.map.addGeoJsonObjects(JSON.stringify(item.geometry), false, item.color, item.add_polyline_ends,
                        item.transparency ? item.transparency : 1, item.line_width ? item.line_width : 3)
                }
            })
        },
        handleGeoJsonObjects() {
            if(this.selectedContractIds && this.showContractGeometry && this.selectedGeometryOrders.length > 0) {
                this.hideLoadedGeoJsonObjects();
                this.fetchContractOrdersGeometries()
            } else {
                this.hideLoadedGeoJsonObjects();
            }
        },

        handleTrafficControl() {
            this.trafficControlItemsEnabled = this.showTrafficControl;
        },

        fetchContractOrdersGeometries: function () {
            if (this.selectedContractId || (this.selectedContractIds && this.selectedContractIds.length > 0)) {
                this.loading = true
                this.hideLoadedGeoJsonObjects()
                let params = {}
                if(this.status) {
                    params.status = this.status
                }
                if(this.selectedGeometryOrders) {
                    params.orders = this.selectedGeometryOrders
                } else {
                    // Do not fetch without geometries!!!
                    return
                }
                if(this.selectedContractIds) {
                    params.contract = this.selectedContractIds
                } else if(this.selectedContractId) {
                    params.contract = this.selectedContractId
                }
                this.restFetchParams(this.contractUrl + "/" + null + '/geometry', params, this.handleOrderGeometry)
            }
        },

        handleOrderGeometry: function (response) {
            if (response && response.data) {
                this.loadedGeometries = response.data
                if(this.loadedGeometries && this.loadedGeometries.length > 0) {
                    this.addLoadedGeoJsonMapObjects()
                } else {
                    this.hideLoadedGeoJsonObjects()
                }
            }
            this.loading = false
        },

        pointerCoordinateMove(coord) {
            this.mouseCoordinate = coord
        },

        onGeoJsonHoverEvent(feature, layer, index, importedItemIndex, currentCoordinate) {
            this.showLabel(layer, 'roadNameLabel', this.map.getMapType() === 'HERE' ? currentCoordinate : this.mouseCoordinate)
        },

        onGeoJsonHoverExitEvent() {
            this.map.removeGeoLabels('roadNameLabel')
        },

        removeLabels() {
            if (this.loadedGeometries) {
                this.loadedGeometries.forEach((item, index) => {
                    let collectionKey = this.loadedGeometries[index].name
                    this.map.removeGeoLabels(collectionKey)
                })
            }
        },

        showLabel(layer, labelType, coordinates) {
            let roadName = null
            Object.keys(layer).some(property => {
                let lowerCaseRoadNames = this.roadNames.map(road => road.toLowerCase());

                if (lowerCaseRoadNames.includes(property.toLowerCase())) {
                    roadName = layer[property];
                    return true;
                }
            });
            if(roadName) {
                this.map.addGeoLabel(coordinates.lat, coordinates.lng, roadName, labelType)
            }
        },

        updateLabel(index, visibility = true) {
            if (this.loadedGeometries) {
                // Draw labels on the map
                let labelKey = this.loadedGeometries[index].label
                let collectionKey = this.loadedGeometries[index].name
                // Remove previous ones
                this.map.removeGeoLabels(collectionKey)
                if (labelKey && visibility) {
                    // Add labels to map
                    let geomObject = this.loadedGeometries[index].geometry
                    geomObject.features.forEach(feature => {
                        let lat, lon
                        let label = feature.properties[labelKey]
                        if (label && label.length > 0) {
                            if (Array.isArray(feature.geometry.coordinates[0])) {
                                if (Array.isArray(feature.geometry.coordinates[0][0])) {
                                    lat = feature.geometry.coordinates[0][0][1]
                                    lon = feature.geometry.coordinates[0][0][0]
                                } else {
                                    lat = feature.geometry.coordinates[0][1]
                                    lon = feature.geometry.coordinates[0][0]
                                }
                            } else {
                                lat = feature.geometry.coordinates[1]
                                lon = feature.geometry.coordinates[0]
                            }
                            this.map.addGeoLabel(lat, lon, label, collectionKey)
                        }
                    })
                }
            }
        },

        toggleAreas: function () {
            if (this.areasEnabled) {
                this.hideAreas()
            } else if (this.isAdmin) {
                this.showCompanyAreaSelectionModal()
            } else {
                this.showAreas()
            }
        },

        showAreas: function () {
            this.hideCompanyAreaSelectionModal()
            this.updateMapSettings({ buttons: { areas: { company: this.company } } });
            this.areasEnabled = true
        },

        hideAreas: function () {
            this.areasEnabled = false
            this.updateMapSettings({ buttons: { areas: undefined } });
            this.saveMapSettings();
            this.$refs.mapAreas.clearAreas()
            this.selectedAreaTypes = []
            this.newPolygonId = -1
            this.newPolygonPointCount = 0
        },

        showCompanyAreaSelectionModal: function () {
            this.$refs.companyAreaSelectionModal.show()
        },

        hideCompanyAreaSelectionModal: function () {
            this.$refs.companyAreaSelectionModal.hide()
        },

        toggleVehicles: function () {
            if (this.vehiclesEnabled) {
                this.hideVehicles()
            } else {
                this.showVehicles()
            }
            this.updateMapSettings({ buttons: { vehicles: this.vehiclesEnabled } });
            this.saveMapSettings();
        },

        showVehicles: function () {
            this.vehiclesEnabled = true
        },

        onWorkHistoryChanged(mode, from, to, roadOwner) {
            this.showRoadWorkHistory = mode !== historyModes.NO_HISTORY
            this.historyFromDate = from
            this.historyToDate = to
            this.historyRoadOwner = roadOwner
            this.$nextTick(() => {
                if (this.$refs.snapshot) {
                    this.$refs.snapshot.updateSnapshot()
                }
            })
        },

        hideVehicles: function () {
            this.historyRoadOwner = null
            this.showRoadWorkHistory = false
            this.$refs.vehicles.hideVehicles()
            this.vehiclesEnabled = false
        },

        toggleSkiTracks: function () {
            if (this.skiingEnabled) {
                this.hideSkiing()
            } else {
                this.showSkiing()
            }
            this.updateMapSettings({ buttons: { skiing: this.skiingEnabled ? { } : undefined } });
            this.saveMapSettings();
        },
        showSkiing: function () {
            this.skiingEnabled = true
        },

        hideSkiing: function () {
            this.$refs.skiTracks.setSelectedCity(undefined);
            this.$refs.skiTracks.hideTracks()
            this.skiingEnabled = false
        },

        toggleDailyRoutes: function () {
            if (this.dailyRoutesEnabled) {
                this.hideDailyRoutes()
            } else {
                this.showDailyRoutes()
            }
        },

        showDailyRoutes: function () {
            this.dailyRoutesEnabled = true
        },

        hideDailyRoutes: function () {
            this.dailyRoutesEnabled = false
        },

        onMarkerTap: function (data) {
            this.$refs.selectMarkerModal.hide();
            this.$refs.mapContainer.hideMapInfo()
            switch (data.type) {
                case this.VEHICLE:
                    this.showVehicleInfo(data)
                    break
                case this.OBSERVATION:
                    this.showObservationDetails(data)
                    break
                case this.USER:
                    this.$refs.users.showUserDetails(data)
                    break
                case this.MATERIAL_STORAGE:
                    this.editStorage = JSON.parse(JSON.stringify(this.$refs.storages.getStorage(data.id)))
                    break
                case this.WEATHER_STATION:
                    this.weatherStation = this.$refs.weatherStations.getWeatherStation(data.id)
                    break
                case this.MATERIAL_STATION:
                    this.editStation = JSON.parse(JSON.stringify(this.$refs.stations.getStation(data.id)))
                    break
                case this.WORK_ASSIGNMENT:
                    this.editWorkAssignment = this.$refs.workAssignments.getWorkAssignment(data.id)
                    if (this.editWorkAssignment) {
                        this.$refs.assignmentEditor.show()
                    }
                    break
                case this.USER_ALERT:
                    this.$refs.userAlerts.editAlert(data.id)
                    break;
                case this.TRAFFIC_SIGN:
                    this.roadSign = this.$refs.infra.$refs.roadSigns.getVisibleSigns().find(item => item.id === data.id)
                    break;
                case this.TRAFFIC_CONTROL_ITEM:
                    this.showTrafficControlItemDetails(data)
                    break;
                case this.POWER_CENTER:
                    this.$refs.infra.$refs.streetLightMap.editPowerStation(data.id)
                    break;
                case this.STREET_LIGHT:
                    this.$refs.infra.$refs.streetLightMap.editStreetLight(data.id)
                    break;
                case this.CABLE:
                    this.$refs.infra.$refs.streetLightMap.editCable(data.id)
                    break;
                case this.DRAINS:
                    this.$refs.infra.$refs.sewers.editDrain(data.id)
                    break;
                case this.EXTERNAL_TRAFFIC_SIGNS:
                    this.$refs.velho.editTrafficSign(data.id)
                    break;
                case this.EXTERNAL_CULVERTS:
                    this.$refs.velho.editCulvert(data.id)
                    break;
                case this.EXTERNAL_POSTS:
                    this.$refs.velho.editPost(data.id)
                    break;
                case this.EXTERNAL_RAILINGS:
                    this.$refs.velho.editRailing(data.id)
                    break;
                case this.EXTERNAL_PORTALS:
                    this.$refs.velho.editPortal(data.id)
                    break;
                case this.EXTERNAL_DRAINS:
                    this.$refs.velho.editDrain(data.id)
                    break;
                case this.EXTERNAL_FENCES:
                    this.$refs.velho.editFence(data.id)
                    break;
                case this.EXTERNAL_STAIRS:
                    this.$refs.velho.editStair(data.id)
                    break;
                case this.EXTERNAL_GATES:
                    this.$refs.velho.editGate(data.id)
                    break;
                case this.EXTERNAL_EDGE_SUPPORTS:
                    this.$refs.velho.editEdgeSupport(data.id)
                    break;
                case this.EXTERNAL_EDGE_PILES:
                    this.$refs.velho.editEdgePile(data.id)
                    break;
                case this.EXTERNAL_ROADSIDE_FURNITURE:
                    this.$refs.velho.editRoadsideFurniture(data.id)
                    break;
                case this.EXTERNAL_ROADSIDE_ADVERTISEMENTS:
                    this.$refs.velho.editRoadsideAdvertisement(data.id)
                    break;
                case this.EXTERNAL_BOOMS_BARRIERS_AND_BOLLARDS:
                    this.$refs.velho.editBoomOrBarrierOrBollard(data.id)
                    break;
                case this.EXTERNAL_PIPES_WIRES_AND_CABLES:
                    this.$refs.velho.editPipeWireCable(data.id)
                    break;
                case this.EXTERNAL_LIGHTING:
                    this.$refs.velho.editLighting(data.id)
                    break;
                case this.HARJA_MESSAGES:
                    this.$refs.velho.editHarja(data.id)
                    break;
            }
        },

        onMultiMarkerTap(data) {
            const sources = {
                [this.OBSERVATION]: () => this.$refs.observations.getVisibleObservations(),
                [this.MATERIAL_STORAGE]: () => this.$refs.storages.storages,
                [this.MATERIAL_STATION]: () => this.$refs.stations.stations,
                [this.WORK_ASSIGNMENT]: () => this.$refs.workAssignments.getVisibleWorkAssignments(),
                [this.VEHICLE]: () => this.$refs.vehicles.logEntries,
                [this.USER]: () => this.$refs.users.logEntries,
                [this.USER_ALERT]: () => this.$refs.userAlerts.alerts,
                [this.STREET_LIGHT]: () => this.$refs.infra.$refs.streetLightMap.visibleLights,
                [this.POWER_CENTER]: () => this.$refs.infra.$refs.streetLightMap.visibleStations,
                [this.SEWERS]: () => this.$refs.infra.$refs.sewers.sewers,
                [this.DRAINS]: () => this.$refs.infra.$refs.sewers.drains,
                [this.TRAFFIC_SIGN]: () => this.$refs.infra.$refs.roadSigns.visibleSigns,
                [this.TRAFFIC_CONTROL_ITEM]: () => this.$refs.trafficControlMap.trafficControlItems,
                [this.CABLE]: () => this.$refs.infra.$refs.streetLightMap.visibleStations,
                [this.EXTERNAL_TRAFFIC_SIGNS]: () => this.$refs.velho.$refs.signsExt.roadSigns,
                [this.EXTERNAL_POSTS]: () => this.$refs.velho.$refs.postsExt.posts,
                [this.EXTERNAL_CULVERTS]: () => this.$refs.velho.$refs.culvertsExt.culverts,
                [this.EXTERNAL_RAILINGS]: () => this.$refs.velho.$refs.railingsExt.railings,
                [this.EXTERNAL_DRAINS]: () => this.$refs.velho.$refs.drainsExt.results,
                [this.EXTERNAL_PORTALS]: () => this.$refs.velho.$refs.portalsExt.results,
                [this.EXTERNAL_STAIRS]: () => this.$refs.velho.$refs.stairsExt.results,
                [this.EXTERNAL_GATES]: () => this.$refs.velho.$refs.gatesExt.results,
                [this.EXTERNAL_FENCES]: () => this.$refs.velho.$refs.fencesExt.fences,
                [this.EXTERNAL_EDGE_SUPPORTS]: () => this.$refs.velho.$refs.edgeSupportsExt.results,
                [this.EXTERNAL_EDGE_PILES]: () => this.$refs.velho.$refs.edgePilesExt.results,
                [this.EXTERNAL_ROADSIDE_FURNITURE]: () => this.$refs.velho.$refs.roadsideFurnitureExt.results,
                [this.EXTERNAL_ROADSIDE_ADVERTISEMENTS]: () => this.$refs.velho.$refs.roadsideAdvertisementsExt.results,
                [this.EXTERNAL_BOOMS_BARRIERS_AND_BOLLARDS]: () => this.$refs.velho.$refs.boomsBarriersBollardsExt.results,
                [this.EXTERNAL_LIGHTING]: () => this.$refs.velho.$refs.lightingExt.results,
                [this.EXTERNAL_PIPES_WIRES_AND_CABLES]: () => this.$refs.velho.$refs.pipesWiresCablesExt.results,
                [this.HARJA_MESSAGES]: () => this.$refs.velho.$refs.harja.results,
            };
            const elements = data
                .map(data => {
                    const src = sources[data.type];
                    if (src == null) {
                        return null;
                    }
                    let element = {
                        type: data.type,
                        id: data.id,
                    }
                    const isExternalType = [this.EXTERNAL_TRAFFIC_SIGNS, this.EXTERNAL_POSTS,
                        this.EXTERNAL_CULVERTS, this.EXTERNAL_RAILINGS, this.EXTERNAL_DRAINS,
                        this.EXTERNAL_PORTALS, this.EXTERNAL_GATES, this.EXTERNAL_FENCES,
                        this.EXTERNAL_EDGE_SUPPORTS, this.EXTERNAL_EDGE_PILES,
                        this.EXTERNAL_ROADSIDE_FURNITURE, this.EXTERNAL_ROADSIDE_ADVERTISEMENTS,
                        this.EXTERNAL_BOOMS_BARRIERS_AND_BOLLARDS, this.EXTERNAL_LIGHTING,
                        this.EXTERNAL_PIPES_WIRES_AND_CABLES, this.EXTERNAL_STAIRS].includes(data.type);

                    element.data = isExternalType
                        ? src().find(el => el.external_system_id === data.id)
                        : src().find(el => el.id === data.id);
                    return element
                })
                .filter(el => el != null);
            this.$refs.selectMarkerModal.show();
            this.markerSelections = elements;
        },

        toggleObservations: function () {
            if (this.observationsEnabled) {
                this.hideObservations()
            } else {
                this.showObservations()
            }
            this.updateMapSettings({ buttons: { observations: this.observationsEnabled ? { } : undefined } });
            this.saveMapSettings();
        },

        showObservations: function () {
            this.observationsEnabled = true
        },

        closeAndUpdateObservation() {
            this.measureDistanceTargetItem = null
            this.showObservationWizardOnCoord = null
            if (this.observationsEnabled) {
                this.$refs.observations.fetchObservations(true)
            }
        },

        lineForObservation(observation) {
            let coord = {
                lat: observation.geometry.line_string.points[0][1],
                lng: observation.geometry.line_string.points[0][0]
            }
            this.showObservationWizardOnCoord = null
            this.measureDistanceTargetItem = observation;
            this.initMeasureDistance(coord)
        },

        hideObservations: function () {
            this.observationsEnabled = false
        },

        openAddressSearchWizard(coord) {
            this.showAddressSearchWizardOnCoord = coord
        },

        openRoadSearchWizard(coord) {
            this.showRoadSearchWizardOnCoord = coord
        },
        openWorkSearchWizard(coord) {
            this.showWorkSearchWizardOnCoord = coord
        },

        searchRoadAddress(coord) {
            this.loading = true
            this.restFetchParams(this.roadAddressUrl, {lat: coord.lat, lon: coord.lng}, this.handleRoadAddress)
        },

        handleRoadAddress(response) {
            this.loading = false
            if (response && response.data) {
                this.showRoadAddressBubble(response.data)
            }
        },

        showRoadAddressBubble(data) {
            let bubbleData = []
            bubbleData[this.$t('map.road_name')] = data.name
            bubbleData[this.$t('map.road_number')] = data.roadNum
            bubbleData[this.$t('map.road_section')] = data.secNum
            bubbleData[this.$t('map.distance')] = data.distanceSecStart + " / " + data.roadSecLength
            bubbleData[this.$t('map.city')] = data.city
            this.map.showInfoBubbleWithoutItem(this.$t('map.search_road_address_location'), bubbleData, data.nearestLat, data.nearestLon)
        },

        openObservationWizard(coord) {
            this.showObservationWizardOnCoord = [coord]
        },

        openWorkAssignmentEditor(coord) {
            this.showWorkAssignmentEditorOnCoord = [coord]
            this.$refs.assignmentEditor.show()
        },

        openRoadSignWizard(coord) {
            this.showRoadSignWizardOnCoord = coord
        },
        openVelhoEquipmentWizard(coord) {
            this.showVelhoEquipmentWizardOnCoord = coord
        },
        openImportShapeFileWizard(coord) {
            this.showImportShapeFileWizardOnCoord = coord;
        },

        openStreetLightWizard(coord) {
            if (!this.infraEnabled) {
                this.infraEnabled = true
            }
            this.$nextTick(() => {
                this.$refs.infra.addStreetLight(coord)
            })
        },

        openPowerStationWizard(coord) {
            if (!this.infraEnabled) {
                this.infraEnabled = true
            }
            this.$nextTick(() => {
                this.$refs.infra.addPowerStation(coord)
            })
        },

        success: function (response) {
            this.loading = false
            this.showAlert(this.$t('common.alert_update_success'), 'success')
            this.removeMeasureDistances()
            this.measureDistanceTargetItem = response
            this.showObservationWizardOnCoord = response
        },

        fail: function () {
            this.loading = false
            this.showAlert(this.$t('common.alert_update_failed'))
            this.removeMeasureDistances()
            this.showObservations()
        },

        toggleStreetLights: function () {
            this.streetLightsEnabled = !this.streetLightsEnabled
            this.updateMapSettings({ buttons: { streetLights: this.streetLightsEnabled } });
            return this.saveMapSettings();
        },

        showRoadSigns: function () {
            this.roadSignsEnabled = true
        },

        hideRoadSigns: function (disableButton = true) {
            if(disableButton) {
                this.roadSignsEnabled = false
            }
            this.roadSigns = []
            this.$refs.roadSigns.hideSigns()
        },

        handleRoadSignResponse: function (response) {
            this.loading = false
            this.roadSigns = response.data
        },

        handleRoadSignsError: function () {
            this.loading = false
        },

        closeRoadSignWizard() {
            this.showRoadSignWizardOnCoord = null
            this.roadSign = null
        },
        closeVelhoEquipmentWizard() {
            this.showVelhoEquipmentWizardOnCoord = null
        },
        closeAndUpdateRoadSign() {
            this.showRoadSignWizardOnCoord = null
            this.roadSign = null
            if (this.$refs.infra && this.$refs.infra.$refs.roadSigns) {
                this.$refs.infra.$refs.roadSigns.updateView()
            }
        },

        closeImportShapeFileWizard() {
            this.showImportShapeFileWizardOnCoord = null
        },

        addGeoJsonMapObjects(geoJson) {
            this.map.addGeoJsonObjects(geoJson)
            this.showImportedObjects = true
            this.showImportShapeFileWizardOnCoord = null
        },
        hideImportedObjects: function () {
           this.showImportedObjects = false
           this.map.hideGeoJsonObjects()
        },

        toggleWorkAssignments: function () {
            if (this.workAssignmentsEnabled) {
                this.hideWorkAssignments()
            } else {
                this.showWorkAssignments()
            }
            this.updateMapSettings({ buttons: { workAssignments: this.workAssignmentsEnabled } });
            return this.saveMapSettings();
        },

        toggleInfraData: function () {
            this.infraEnabled = !this.infraEnabled
        },

        toggleVelhoData: function () {
            this.velhoEnabled = !this.velhoEnabled
            this.updateMapSettings({ buttons: { velhoEquipment: this.velhoEnabled }});
            return this.saveMapSettings();
        },

        showWorkAssignments: function () {
            this.workAssignmentsEnabled = true
        },

        hideWorkAssignments: function (disableButton = true) {
            if(disableButton) {
                this.workAssignmentsEnabled = false
            }
            this.$refs.workAssignments.hideAssignments()
        },

        addMaterialStorage(coord) {
            this.editStorage = newMaterialStorage('', coord.lat, coord.lng)
        },

        toggleStorages() {
            this.storagesEnabled = !this.storagesEnabled
            this.updateMapSettings({ buttons: { storages: this.storagesEnabled }});
            return this.saveMapSettings();
        },

        closeAndUpdateWorkAssignment() {
            this.hideAssignmentEditor()
            if (this.workAssignmentsEnabled) {
                this.$refs.workAssignments.updateWorkAssignments(true)
            }
        },

        hideAssignmentEditor() {
            this.editWorkAssignment = null
            this.showWorkAssignmentEditorOnCoord = null
            this.$refs.assignmentEditor.hide()
        },

        closeAndUpdateStorage() {
            this.editStorage = null
            if (this.storagesEnabled) {
                this.$refs.storages.updateStorages(true)
            }
        },

        addMaterialStation(coord) {
            this.editStation = this.newMaterialStation(coord.lat, coord.lng)
        },

        openDrainWizard(coord){
            if (!this.infraEnabled) {
                this.infraEnabled = true
            }
            this.$nextTick(() => {
                this.$refs.infra.addDrain(coord)
            })
        },

        toggleStations() {
            this.stationsEnabled = !this.stationsEnabled
            this.updateMapSettings({ buttons: { stations: this.stationsEnabled } });
            return this.saveMapSettings();
        },


        toggleWeatherStations() {
            this.weatherStationsEnabled = !this.weatherStationsEnabled
            this.updateMapSettings({ buttons: { weatherStations: this.weatherStationsEnabled } });
            return this.saveMapSettings();
        },


        closeAndUpdateStation() {
            this.editStation = null
            if (this.$refs.stations) {
                this.$refs.stations.updateStations()
            }
        },

        toggleUsers: function () {
            if (this.usersEnabled) {
                this.$refs.users.hideUsers()
                this.usersEnabled = false
            } else {
                this.usersEnabled = true
                this.isGeolocationEnabled()
            }
            this.updateMapSettings({ buttons: { users: this.usersEnabled } });
            return this.saveMapSettings();
        },

        isGeolocationEnabled: function () {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(() => {
                }, () => {
                    EventBus.$emit('show-alert', this.$t('common.alert_location_disabled'))
                }, {timeout: 30000, enableHighAccuracy: true})
            }
        },

        scrollTop: function () {
            this.$nextTick(function () {
                this.$refs.container.scrollTop = 0
            })
        },

        cancelLineDrawing() {
            this.measureDistanceTargetItem = null
            this.showObservationWizardOnCoord = null
            this.removeMeasureDistances()
        }
    }
}
</script>
<style lang="scss">
    .map-controls {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        pointer-events: none;
        padding-right: .25rem;
        padding-left: .25rem;
        max-height: 82vh;
        overflow: clip;

        &__list {
            align-self: flex-end;
            position: relative;
            z-index: 2;
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-start;
            text-overflow: ellipsis;
            pointer-events: all;
            overflow: auto;
        }
    }

   ul.suggestions-list {
       list-style: none;
       padding: 0;
       margin: 1rem 0 0 0;
       max-height: 400px;
       overflow: auto;

       li {
           padding: 1em 0.5em;
           border-bottom: 1px solid rgba(0, 0, 0, 0.1);
           transition: background-color 0.25s ease-out;
           cursor: pointer;

           &:last-child {
               border-bottom: none;
           }
           &:only-child {
               border-top: 1px solid rgba(0, 0, 0, 0.1);
               border-bottom: 1px solid rgba(0, 0, 0, 0.1);
           }
           &:hover {
               background-color: rgba(0, 0, 0, 0.1);
           }
       }
   }
</style>
